class MarketingTrackingPlugin {
    constructor(tracker) {
        this.origin = {};
        this.tracker = tracker;
    }

    track() {
        var regexp = /utm_/;
        if (regexp.test(document.location.href)) {
            var paramMap = {
                'utm_source': 'source',
                'utm_medium': 'channel',
                'utm_campaign': 'campaign',
                'utm_content': 'content',
                'utm_term': 'terms'
            };
            var urlParams = document.location.href.split('?');
            var queryPairs = urlParams[1].split('#');
            queryPairs = queryPairs[0].split('&');

            for (var idx in queryPairs) {
                var pairArr = queryPairs[idx].split('=');
                if (paramMap.hasOwnProperty(pairArr[0])) {
                    this.origin[paramMap[pairArr[0]]] = pairArr[1];
                }
            }

            pulse('update', { origin: this.origin });
        }
    }
}

pulse('provide', 'marketingtracking',  MarketingTrackingPlugin);
